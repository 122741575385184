<template>
  <div>
    <TooltipButton
      class="text-red-500 hover:text-red-400 duration-150"
      @click="modalOpen = true"
    >
      <template v-slot:content>
        <Icon
          icon="Bin"
          view-box="0 0 24 24"
          class="w-6 h-6 ml-1 fill-none"
        >
          <Bin />
        </Icon>
      </template>
      <template v-slot:tooltip>
        Delete test
      </template>
    </TooltipButton>

    <Modal
      :open="modalOpen"
      @close="attemptClose()"
    >
      <div class="border-b-2 bg-white">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="font-medium text-lg">
            Delete test
          </h3>
          <button
            v-if="!isProcessing"
            class="text-gray-500 focus:outline-none"
            @click="modalOpen = false"
          >
            <Icon
              width="16px"
              height="16px"
              view-box="0 0 14 14"
              class="transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>
      <div
        v-if="!isProcessing"
        class="m-6"
      >
        <p>Are you sure you want to delete <strong>{{ exam.name }}</strong>?</p>

        <Notice
          class="my-6"
          variant="info"
        >
          This test will be removed from your organisation’s test suite. Candidates who have already been sent this test will still be able to access it.
        </Notice>

        <div class="flex justify-between my-6">
          <BaseButton
            variant="gray"
            @click="modalOpen = false"
          >
            No, cancel
          </BaseButton>
          <BaseButton
            variant="danger"
            @click="deleteExam"
          >
            Yes, delete
          </BaseButton>
        </div>
      </div>
      <div
        v-else
        class="my-16"
      >
        <Loader />
      </div>
    </Modal>
  </div>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Bin from '@components/Icons/Bin'
import Loader from '@components/Loader'
import Modal from '@components/Modal'
import Notice from '@components/Notice'
import Plus from '@components/Icons/Plus'
import TooltipButton from '@components/TooltipButton'
import organisationExamsApi from '@api/organisationExams'

import { mapGetters } from 'vuex'

export default {
  components: {
    Icon,
    Loader,
    Modal,
    Notice,
    Plus,
    Bin,
    TooltipButton
  },

  props: {
    exam: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      isProcessing: false,
      modalOpen: false
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    })
  },

  methods: {
    attemptClose() {
      if (this.isProcessing) {
        return // Cannot close while processing
      }
      this.modalOpen = false
    },

    deleteExam() {
      return organisationExamsApi.archiveExam(this.organisationId, this.exam.uuid)
        .then(exam => {
          this.$emit('examDeleted')
          this.$router.push({ name: 'test-builder' })
        })
        .catch(error => {
          throw error
        })
    }
  }
}
</script>
