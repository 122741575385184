<template>
  <div>
    <SlideOverPanelTrigger
      :close="close"
      :disabled="disabled"
    >
      <template v-slot:text>
        <TooltipButton
          :class="['hover:text-secondary duration-150', disabled ? 'text-gray-400' : 'currentColor']"
          @click="modalOpen = true"
        >
          <template v-slot:content>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
          </template>
          <template v-slot:tooltip>
            Preview test <span v-if="disabled">(add at least one question)</span>
          </template>
        </TooltipButton>
      </template>

      <template v-slot:default>
        <PreviewTestContainer
          :exam="examAndQuestions"
          @timeIsUp="timeIsUp"
          @finish="close = true"
        />

        <PreviewTestTimeUpModal
          v-if="timeUpModalOpen"
          :modal-open="timeUpModalOpen"
          @finish="close = true"
        />
      </template>
    </SlideOverPanelTrigger>
  </div>
</template>

<script>
import PreviewTestContainer from '@components/TestBuilder/PreviewTestContainer'
import SlideOverPanelTrigger from '@components/SlideOverPanelTrigger'
import TooltipButton from '@components/TooltipButton'
import PreviewTestTimeUpModal from '@components/TestBuilder/PreviewTestTimeUpModal'

export default {
  components: {
    PreviewTestContainer,
    SlideOverPanelTrigger,
    TooltipButton,
    PreviewTestTimeUpModal
  },

  props: {
    exam: {
      type: Object,
      default: null
    },
    questions: {
      type: Array,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      close: false,
      timeUpModalOpen: false
    }
  },

  computed: {
    // @NOTE: This should probably all come in a "real" test format
    // from the backend
    examAndQuestions() {
      return {
        ...this.exam,
        questions: this.questions.map(question => {
          return {
            ...question,
            type: 'choice',
            image_url: question.image && `https://res.cloudinary.com/${question.image.cloudName}/image/upload/q_70/v${question.image.version}/${question.image.publicId}.${question.image.format}`
          }
        }),
        questionCount: this.questions.length,
        fullName: this.exam.name,
        slug: this.exam.name
      }
    }
  },

  methods: {
    timeIsUp() {
      this.timeUpModalOpen = true
    }
  }
}
</script>
